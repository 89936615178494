<template>
  <div>
    <SubscriptionIndex v-if="step == 2" />
  </div>
</template>

<script>
import SubscriptionIndex from "@/views/configuracoes/Assinatura.vue";
export default {
  components: { SubscriptionIndex },
  data: () => ({ step: 1 }),
};
</script>

<style lang="sass" scoped>
.page
  background-color: #F0EFF8
.component
  margin-top: 5%
  display: flex
  align-items: center
  justify-content: center
  align-content: center
</style>